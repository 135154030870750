import React,{useEffect,useState} from "react";
import  'bootstrap/dist/css/bootstrap.min.css';
import banner from '../components/home-slider-bg-02.jpeg';
import loadable from '@loadable/component'
import Login from './Login';
import trustedSoftwareLogo from  '../components/images/01.png' ;
import softwareDevelopmentLogo from  '../components/images/02.png' ;
import criticalRequirements from  '../components/images/03.png' ;
import explore from "../components/explore.jpeg";
import controllerDesign from "../components/controllerDesign.jpeg";
import dezyneModel from "../components/DezyneModel.jpeg"
const Footer = loadable(() => import('../components/Footer'))
const Nav = loadable(() => import ('../components/Nav'))
// const TopNav = loadable(() => import ('../components/TopNav'))
const ImageAnimated = loadable(() => import('../animatedImage'))

function App() {
  const  [user, setUser] = useState("")

  return (
    <>
{/* <TopNav/> */}
<Nav/>


<section className="top-space wow fadeIn cover-background padding-130px-tb" style={{backgroundImage:`url(${banner})`,marginTop:"80px"}} >
            <div className="container">
                <div className="row min-height-200px align-items-center justify-content-center text-center">
                    <div className="col-12 col-lg-6 md-margin-50px-bottom sm-margin-30px-bottom">
                        <h3 className="text-dark-gray font-weight-700 letter-spacing-minus-1 line-height-50 margin-25px-bottom lg-line-height-auto sm-margin-15px-bottom text-uppercase">Discover<br/>Verum Dezyne</h3>
                        <h6 className="alt-font text-green margin-50px-bottom sm-margin-15px-bottom">A power language</h6>
                        <a href="/verumdezyne" className="btn btn-green border-radius-5 btn-medium no-margin-lr letter-spacing-2" style={{margin:"0px !important" }}>Verum Dezyne</a>
                    </div>
                </div>
            </div>
        </section>

        <section class="bg-home-tab no-padding-bottom padding-70px-top wow fadeIn">
            <div class="container tab-style2">
                <div class="row align-items-center justify-content-center text-center">
                    <div class="col-12 text-center">
                        <h3 class="title-section alt-font text-dark-gray letter-spacing-1 margin-40px-bottom">Rich tools to support the development<br/>of complex embedded software</h3>
                    </div>
                    <div class="col-12 col-lg-4 margin-30px-bottom">
                        <img src={trustedSoftwareLogo} alt="" class="margin-25px-bottom border-radius-100 width-100px"/>
                        <div class="text-dark-gray text-large font-weight-400 margin-15px-bottom md-margin-5px-bottom alt-font">Create software that is<br/>proven to be trusted.</div>
                    </div>
                    <div class="col-12 col-lg-4 margin-30px-bottom">
                        <img src={softwareDevelopmentLogo} alt="" class="margin-25px-bottom border-radius-100 width-100px"/>
                        <div class="text-dark-gray text-large font-weight-400 margin-15px-bottom md-margin-5px-bottom alt-font">Reduces the cost and time<br/>of software development.</div>
                    </div>
                    <div class="col-12 col-lg-4 margin-30px-bottom">
                        <img src={criticalRequirements} alt="" class="margin-25px-bottom border-radius-100 width-100px"/>
                        <div class="text-dark-gray text-large font-weight-400 margin-15px-bottom md-margin-5px-bottom alt-font">Easy handling of<br/>safety-critical requirements.</div>
                    </div>
                </div>
            </div>
        </section>

        <section class="padding-70px-tb wow fadeInUp">
            <div class="container tab-style2">
                <div class="row align-items-center">
                    <div class="col-12 text-center">
                        <h3 class="title-section alt-font text-dark-gray letter-spacing-1">About Verum</h3>
                        <p>A leading developer of software design tools to create, simulate, mathematically verify and automatically generate code for embedded and cyber-physical systems.</p>
                        <p>We support software engineers with toolsets and solutions that <strong>create defect-free software, fast and reliable</strong>. We make applying formal methods trivial, in our tools the verification works the for the engineer the same way as a grammar checker in a word processor.</p>
                        <p class="margin-40px-top no-margin-bottom"><a href="/verumdezyne" class="btn btn-green border-radius-5 btn-medium no-margin-lr letter-spacing-2">Read More</a></p>
                    </div>
                </div>
            </div>
        </section>
        <section class="padding-70px-tb wow fadeIn bg-download-landing">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 text-center">
                        <h3 class="title-section alt-font text-dark-gray letter-spacing-1 margin-40px-bottom">Verum Dezyne Features</h3>
                    </div>
                </div>
                <div class="row justify-content-center margin-30px-bottom">
                    <div class="col-12 col-lg-4 margin-30px-bottom">
                        <h6 class="title-section-alt text-dark-gray font-weight-400 margin-5px-bottom alt-font">Dezyne Language</h6>
                        <p>Dezyne is an open, C-like language for developing complex embedded software. It uses a "Design by Contract" approach, serving as an intermediary between formal models and target source code. Dezyne's building blocks are interfaces and components, enabling the creation of complex systems through composition. Its interactions are visualized as state diagrams, allowing for easier control flow analysis.</p>
                    </div>
                    <div class="col-12 col-lg-8 margin-30px-bottom"><img src={explore} alt=""/></div>
                </div>
                <div class="row justify-content-center margin-30px-bottom">
                    <div class="col-12 col-lg-4 margin-30px-bottom">
                        <h6 class="title-section-alt text-dark-gray font-weight-400 margin-5px-bottom alt-font">Dezyne Model</h6>
                        <p>Dezyne supports portability and compatibility. Dezyne code can be deployed in complex, large-scale, safety-critical control systems, as well as in industrial PLCs, web application and even on small chips such as the ESP32 for home applications:<br/><br/><a href="https://forum.verum.com/t/implementing-the-dezyne-model-on-the-esp32-chip/113" target="_blank" class="text-green">https://forum.verum.com/t/implementing-the-dezyne-model-on-the-esp32-chip/113</a> </p>                    </div>
                    <div class="col-12 col-lg-8 margin-30px-bottom"><img src={dezyneModel} alt=""/></div>
                </div>
                <div class="row justify-content-center margin-30px-bottom">
                    <div class="col-12 col-lg-4 margin-30px-bottom">
                        <h6 class="title-section-alt text-dark-gray font-weight-400 margin-5px-bottom alt-font">Validation and Exploration</h6>
                        <p>Verum Dezyne offers graphical tools to validate and test the structure of the system and its specification and behavior. The Sequence Diagram lets users simulate the system, inspect variables, events and responses. The State Diagram explores every possible system state and its contributing factors. Dezyne's batch mode is compatible with modern CI/CD toolchains, enabling seamless testing and validation.</p>
                    </div>
                    <div class="col-12 col-lg-8 margin-30px-bottom"><img src={controllerDesign} alt=""/></div>
                </div>
                <div class="row align-items-center">
                    <div class="col-12 text-center">
                        <p class="no-margin-bottom"><a href="/verumdezyne" class="btn btn-green border-radius-5 btn-medium no-margin-lr letter-spacing-2">Verum Dezyne Features</a></p>
                    </div>
                </div>
            </div>
        </section>


      <Footer/>
      </>
  );
}

export default App;
